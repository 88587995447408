import React from 'react'
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'

function Schoolmangement() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>School Mangement Software</h3>

<p style={{textAlign:"justify"}}>Elevate your educational institution's efficiency with our cutting-edge School Management Software (SMS) designed as a service. Our platform seamlessly integrates robust features to streamline administrative tasks, enhance communication, and foster a conducive learning environment</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Student Information Management
 </p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Attendance Tracking </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Fee Management</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Employee and Staff Management</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Classroom and Time Management</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Data-driven Decision Making</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Secure and Scalable</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong></p>

</ul> */}
<p style={{textAlign:"justify"}}>Empower students with seamless communication, personalized learning, and efficient administration through our School Management Software. Additionally, effortlessly manage post-examination processes, ensuring accurate records and delivering digital reports to parents, streamlining administrative tasks for an enhanced educational experience.</p>
{/* <p><strong>Why Us?</strong></p> */}

{/* <ul>

    <p><strong>Easy to Use</strong><br/> No tech headaches here. Our interface is designed with you in mind, making it a breeze for your team to get the hang of things.</p>



    <p><strong>Support that Cares</strong><br/> Need help? Our support team is here for you, ready to assist with any questions or issues.</p>

</ul> */}

<p style={{textAlign:"justify"}}>Transform your school's management with our user-friendly and customizable School Management Software. Experience the power of technology in education. Contact us today to schedule a demo and witness the future of school administration.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Schoolmangement