import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function BusinessWeb() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Business Website</h3>

<p style={{textAlign:"justify"}}>Welcome to our Business Website development service, where innovation meets functionality. We specialize in crafting dynamic, user-friendly websites that not only elevate your online presence but also drive business growth.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Customization
 </p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Responsive Design</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>E-commerce Integration</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Content Management System (CMS)</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Social Media Integration</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Data-driven Decision Making</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Secure and Scalable</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong></p>

</ul> */}
<p style={{textAlign:"justify"}}>Our website development service offers enhanced online presence, boosting brand credibility, expanding reach locally and globally, and optimizing conversions. With scalable solutions and ongoing technical support, we ensure your website stays functional and aligned with your business goals.</p>
{/* <p><strong>Why Us?</strong></p> */}

{/* <ul>

    <p><strong>Easy to Use</strong><br/> No tech headaches here. Our interface is designed with you in mind, making it a breeze for your team to get the hang of things.</p>



    <p><strong>Support that Cares</strong><br/> Need help? Our support team is here for you, ready to assist with any questions or issues.</p>

</ul> */}

<p style={{textAlign:"justify"}}>Revitalize your brand online. Connect with us for expert Business Website development.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default BusinessWeb