import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function AnalogCamera() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Analog Camera</h3>

<p style={{textAlign:"justify"}}>Step into reliable surveillance with our Analog Cameras, delivering fundamental security. Elevate your security with our cameras designed to provide standard definition clarity and ease of use, ensuring a practical and accessible approach to safeguarding your premises.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Standard Definition Clarity</p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Remote Access and Monitoring  </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Night Vision Capabilities</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Motion Detection</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Auto Backup</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Opt for cost-effective security solutions with our Analog Cameras which are perfect for businesses prioritizing simplicity and basic surveillance needs. These user-friendly cameras feature a straightforward setup and are ideal for smaller spaces, focusing on localized surveillance. Leveraging proven technology, analog cameras assure reliability, offering a stable and dependable solution for your security requirements.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>For straightforward and cost-efficient security solutions, explore the reliability of our Analog Cameras.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default AnalogCamera