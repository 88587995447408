import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function Android() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Android Application</h3>

<p style={{textAlign:"justify"}}>Our Android App development service harnesses the versatility of the platform, crafting innovative and user-friendly solutions. With a vast user base and a robust ecosystem, our expertise opens doors to reaching a broad audience.</p>

<p><strong>Top Categories</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Restaurant and Food Delivery Apps</p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Tourism and Travel Apps </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Educational Apps</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Healthcare Apps</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Customized Apps</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Application development propels business growth through expanded reach, heightened brand visibility, and operational efficiency. Customers benefit from enhanced convenience, personalized experiences, and secure, time-saving interactions, establishing a mutually advantageous dynamic.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Elevate your business with our Android App development service innovation, reach, and efficiency at your fingertips.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Android