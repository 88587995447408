import React from "react";
// import "./Home.css";
import "../Home/OurProduct.css";
import SecurityGates from "./SecurityGates/SecurityGates";
import SmartDoorLock from "./SmartDoorLock/SmartDoorLock";
import BiomatricDevices from "./BiomatricDevices/BiomatricDevices";
import EsSecurity from "./EsSecurity/EsSecurity";
// import MainProduct from "../MainProducts/MainProduct";
// import Pointofsale from "../Pointofsale/Pointofsale";
// import Hotelmangment from "../HotelManagment/Hotelmangment";
// import Schoolmangement from "../Schoolmangment/Schoolmangement";
// import Hospitalmangment from "../HospitalMangment/Hospitalmangment";
// import PropertyMangment from "../PropertyManagment/PropertyMangment";
// import Pharmacy from "../PharmacyMangment/Pharmacy";
// import CostomizeSoftware from "../CostomizeSoftware/Costomizesoftware";
// import Hardwareintegration from "../Hardwareintegration/Hardwareintegration";


function AccessControlComp() {
  return (
    <>
      <div className="container-fluid mt-3  flex-wrap mb-5" >
        <h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}
        >
       Access Control System
        </h1>
        <p style={{ textAlign: "center" }}>
      
        Empowering security with seamless solutions for access control excellence.
         </p>

<div className="row mt-3" style={{display:"flex",justifyContent:"center"}} >
  <div className="fortab col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Smart Door Locks</h5>
    <p class="card-text" style={{alignItems:"center"}}>Our Smart Door Locks offer a seamless blend of advanced security features </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#smartdoorlock" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Security Gates</h5>
    <p class="card-text" style={{alignItems:"center"}}>We proudly present our cutting-edge Smart Security Gates service.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#securitygates" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Biometric Devices</h5>
    <p class="card-text" style={{alignItems:"center"}}>Step into secure access and identity verification with our Biometric Devices. </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#biomatricdevices" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >EAS Security Antennas</h5>
    <p class="card-text" style={{alignItems:"center"}}>EAS Security Antennas are a proactive deterrent against theft.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#essecurity" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  </div>


      </div>


{/* <MainProduct/> */}


{/* modal */}



{/* <!-- Modal --> */}
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="securitygates" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <SecurityGates/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="smartdoorlock" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<SmartDoorLock/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="biomatricdevices" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<BiomatricDevices/>
</div>
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="essecurity" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<EsSecurity/>
</div>


    </>
  );
}

export default AccessControlComp;
