import React from 'react'
import Navbar from '../../Components/Home/Navbar/Navbar'
import Footer from '../../Components/Home/footer/Footer'
import DigitalMarketingComp from '../../Components/DigitalMarketingComp/DigitalMarketingComp'
import Ads from '../../Components/Home/Ads'

function DigitalMarketing() {
  return (
<>
<Ads/>
<Navbar/>
<DigitalMarketingComp/>
<Footer/>
</>
  )
}

export default DigitalMarketing