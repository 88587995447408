import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function PtzCamera() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>PTZ Camera</h3>

<p style={{textAlign:"justify"}}>Experience unparalleled control and surveillance precision with our PTZ (Pan-Tilt-Zoom) Cameras. These advanced cameras redefine the landscape of security monitoring, offering dynamic features to meet the evolving demands of your environment.</p>

<p><strong>Key Features</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>360° Coverage</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Optical Zoom Capabilities </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Pan and Tilt Functionality</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Low light Performance</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Auto Backup 
</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>PTZ Cameras revolutionize surveillance: dynamic responsiveness for changing scenarios, resource optimization by covering expansive areas, precise zoom and focus control for detailed information capture, and customizable presets for tailored monitoring.
</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Elevate security with our PTZ Cameras seamlessly merging cutting-edge tech and versatile functionality for superior monitoring.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default PtzCamera