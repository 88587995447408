import React from 'react'
import LanNetwork from './LanNetwork/LanNetwork'
import PanNetwork from './PanNetwork/PanNetwork'

function NetworkingComp() {
  return (
    <>
    <div className="container-fluid mt-3  flex-wrap mb-5" >
      <h1
        style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}
      >
Networking
      </h1>
      <p style={{ textAlign: "center" }}>
     
      Enhance connectivity seamlessly with our empowering and reliable networking solutions.
       </p>

<div className="row mt-3" style={{display:"flex",justifyContent:"center"}} >
<div className="fortab col-md-3 mt-3 ">
<div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
  {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
<div class="card-body">
  <h5 class="card-title" >Local Area Network</h5>
  <p class="card-text" style={{alignItems:"center"}}>
  Enhance your business connectivity and efficiency with our Local Area Network  service
</p>
  <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#lan" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
</div>
</div>
</div>
<div className="col-md-3 mt-3">
<div class="cardbody11 card text-center" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
  {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
<div class="card-body">
  <h5 class="card-title" >Personal Area Network </h5>
  <p class="card-text" style={{alignItems:"center"}}>  Step into the realm of personalized connectivity with our Personal Area Network service.</p>
  <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#pan" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
</div>
</div>
</div>

</div>


    </div>




{/* modal */}



{/* <!-- Modal --> */}
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="lan" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<LanNetwork/>
</div>


<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="pan" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<PanNetwork/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="googleads" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">

</div>



  </>
  )
}

export default NetworkingComp