import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SoftDev from "./Components/SoftwareDev/SoftDev";
import Webdev from "./Pages/Webdevelopment/Webdev";
import ApplicationDev from "./Pages/ApplicationDev/ApplicationDev";
import AccessControl from "./Pages/AccessControl/AccessControl";
import Cctv from "./Pages/Cctv/Cctv";
import Graphic from "./Pages/GraphicDesining/Graphic";
import DigitalMarketing from "./Pages/DigitalMarketing/DigitalMarketing";
import Networking from "./Pages/Networking/Networking";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import ScrollToTop from "./Pages/ScrollToTop";
import Whatsapp from "./Pages/Whatsapp/Whatsapp";
import TrendingPackage from "./Components/TrendingPackage/TrendingPackage";
function App() {
  return (
    <>
      <div>
        <img
          className="whatsappicon"
          onClick={Whatsapp}
          src={require("../src/Assets/pngegg.png")}
        />
      </div>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/software-development" element={<SoftDev />} />
          <Route path="/website-development" element={<Webdev/>} />
          <Route path="/application-development" element={<ApplicationDev/>} />
          <Route path="/access-control-system" element={<AccessControl />} />
          <Route path="/cctv-surveillance" element={<Cctv/>} />
          <Route path="/graphic-designing" element={<Graphic />} />
          <Route path="/digital-marketing" element={<DigitalMarketing/>} />
          <Route path="/networking" element={<Networking/>} />
          <Route path="/social-media-marketing-trending-packages" element={<TrendingPackage/>} />
 
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
