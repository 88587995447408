import React from 'react'

function Salider() {
  return (
    <>
<div id="carouselExampleAutoplaying" class="carousel  slide " data-bs-ride="carousel" style={{backgroundColor:"#EFEFEF"}}>
  <div class="carousel-inner">
    <div class="carousel-item active">
    <img src={require("../../Assets/C WORLD, Website, Slider (3).jpg")}  class="d-block w-100 " alt="..." style={{maxHeight:"auto"
   }}/>
    </div>
    {/* <div class="carousel-item">
    <img src={require("../../Assets/C WORLD, Website, Slider (1).png")}  class="d-block w-100 " alt="..."style={{maxHeight:"85vh"
    }}/>
    </div> */}
    
    {/* <div class="carousel-item">
    <img src={require("../../Assets/C WORLD, Website, Slider (1).png")}  class="d-block w-100 " alt="..."style={{maxHeight:"85vh"
    }}/>
    </div> */}


  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
    <span class="visually-hidden"></span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
    <span class="visually-hidden"></span>
  </button>
</div>




    </>
  )
}

export default Salider