import React, { useState } from 'react'
import "./Contactus.css"
import emailjs from 'emailjs-com';
function ContactUs() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        adress: '',
        service: '',
        message:"",
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Email.js parameters
        const serviceId = 'service_xa8egpn';
        const templateId = 'template_j4wq9an';
        const userId = 'J3mCKdVMcPsPUQojI';
    
        // Send email
        emailjs.send(serviceId, templateId, formData, userId)
          .then((response) => {
            console.log('Email sent successfully:', response);
          })
          .catch((error) => {
            console.error('Error sending email:', error);
          });
    
        // Clear form after submission
        setFormData({
          name: '',
          email: '',
          phone: '',
        adress: '',
        service: '',
        message:"",
        });
      };
    

  return (
    
<>

<div className='container-fluid mb-5' >
<h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" ,marginTop:"40px"}}
        >
         Get a Quote
        </h1>
        <p style={{ textAlign: "center" }}>
          
For inquiries or assistance, contact us.
         </p>
<div className='col-md-12 mt-2'>
<div className='row m-1 p-5 ' style={{border:"1px solid #ededed",borderRadius:"5px"}}>
<div className='' >

<form class="row g-3" onSubmit={handleSubmit} >
  <div class="col-md-6">
    <label for="inputEmail4" class="form-label" >Name</label>
    <input type="name" class="form-control" required id=""  name="name" value={formData.name} onChange={handleChange}/>
  </div>
  <div class="col-md-6">
    <label for="inputPassword4" class="form-label"  >Phone</label>
    <input type="number" class="form-control" id="" required  name="phone" value={formData.phone} onChange={handleChange}/>
  </div>
  <div class="col-md-6">
    <label for="inputPassword4" class="form-label" >Email</label>
    <input type="email" class="form-control" id=""   name="email" value={formData.email} onChange={handleChange}/>
  </div>

  <div class="col-md-6">
    <label for="inputCity" class="form-label">Address</label>
    <input type="text" class="form-control" id="inputCity"  name="adress" value={formData.adress} onChange={handleChange}/>
  </div>
  <div class="col-md-6">
    <label for="inputState" class="form-label">Select Service</label>
    <select id="inputState" class="form-select"  name="service" value={formData.service} onChange={handleChange}>
      <option className='derpdown' selected>Select</option>
      <option className='derpdown'>Software Development</option>
      <option className='derpdown'>Website Development</option>
      <option className='derpdown'>Application Development</option>
      <option className='derpdown'>Access Control System</option>
      <option className='derpdown'>CCTV Serveillance</option>
      <option className='derpdown'>Graphic Designing</option>
      <option className='derpdown'>Digital Marketing</option>
      <option className='derpdown'>Networking</option>
    
    </select>


  </div>
  <div class="col-md-6">
    <label for="inputState" class="form-label"> Message</label>
  <textarea className='form-control' required  name="message" value={formData.message} onChange={handleChange}>


  </textarea>


  </div>


  <div class="col-md-12" style={{display:"flex",justifyContent:"center"}}>
  <button type="submit" class="cardbody11    mt-4" style={{textDecoration:"none",border:"none",backgroundColor:"#D66E1E",color:"white",minWidth:"200px",height:"40px",borderRadius:"100px"}}>Sent</button>
  </div>
</form>


</div>

</div>
</div>

</div>

</>
  )
}

export default ContactUs
