import React from 'react'
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'

function Hardwareintegration() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Hardware Integration     </h3>

<p style={{textAlign:"justify"}}>Unlock the full potential of your hardware with our integrated software development services. We specialize in crafting software solutions tailored to meet the unique constraints and boundaries set by hardware manufacturers. Our expertise lies in seamlessly merging software and hardware, ensuring a harmonious integration that maximizes functionality. Beyond basic compatibility, we go a step further, customizing features to align precisely with your business requirements. Trust us to navigate the intricacies of hardware limitations, delivering a cohesive solution that enhances the capabilities of your technology infrastructure. Elevate your business with our end-to-end hardware and software integration services.</p>











</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Hardwareintegration