import React from 'react'
import Navbar from '../Home/Navbar/Navbar'
import Footer from '../Home/footer/Footer'
import CardsSection from '../Home/CardsSection'
import OurProducts from '../Home/OurProducts'

function SoftDev() {
  return (
<>
<Navbar/>



<OurProducts/>
<Footer/>

</>
  )
}

export default SoftDev