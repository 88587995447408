import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function Ios() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>iOS Application</h3>

<p style={{textAlign:"justify"}}>Embark on a transformative journey with our iOS App development services, where innovation meets functionality. We specialize in crafting bespoke applications tailored to the unique needs of our clients, delivering cutting-edge solutions that elevate user experiences across the iOS ecosystem.</p>

<p><strong>Top Categories</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Restaurant and Food Delivery Apps</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Tourism and Travel Apps </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Educational Apps</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Healthcare Apps</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Customized Apps</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Application development empowers businesses with expanded reach, increased brand visibility, and operational efficiency. For customers, it translates to enhanced convenience, personalized experiences, and secure, time-saving interactions, fostering a mutually beneficial relationship.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Elevate your digital presence and user engagement with iOS App development where innovation meets success.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default Ios