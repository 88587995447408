import React, { useState, useEffect } from 'react';
import '../Home.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';
import Ads from '../Ads';

function Navbar() {
 

  return (
    <>
    <section className='woowowo' style={{marginBottom:"120px",zIndex:"9999999999999999999999999999999999999999999999999999999999999"}}>
    <div className="yourComponentId" style={{zIndex:"9999999999999999999999999999999999999999"}}>
      {/* <nav className={`navbar navbar-expand-lg w-100 ${scrolled ? 'scrolled' : ''}`} style={{}}> */}
      <Ads/>
      <nav className="navbar  navbar-expand-lg w-100 " style={{  }}>
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src={require("../../../Assets/sirwaseem60.png")}
              // style={{ height: "40px",paddingLeft:"15px" ,borderRadius:"15px"}}
              className="card-img-top navlogo"
              alt="..."
            />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{border:"none"}}>
            <span className="navbar-toggler-icon" style={{ color:'white' }}></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="newone navbar-nav ms-auto px-2">
              {/* 'ms-auto' class is used to push the items to the right */}
              <li className="nav-item px-2">
                <Link to="/" className="nav-link" aria-current="page" >Home</Link>
              </li>
              <li className="nav-item px-2">
              <div class="dropdown">
  <button class="nav-link  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
   Services
  </button>
  <ul class="dropdown-menu">

    <li><Link to="/software-development" class="dropdown-item" >Software Development</Link></li>
    <li><Link to="/website-development" class="dropdown-item" >Website Development</Link></li>
    <li><Link to="/application-development" class="dropdown-item" >Application Development</Link></li>
    <li><Link to="/access-control-system" class="dropdown-item" >Access Control System</Link></li>
    <li><Link to="/cctv-surveillance" class="dropdown-item" >CCTV Serveillance</Link></li>
    <li><Link to="/graphic-designing" class="dropdown-item" >Graphic Designing</Link></li>
    <li><Link to="/digital-marketing" class="dropdown-item" >Digital Marketing</Link></li>
    <li><Link to="/networking" class="dropdown-item" >Networking</Link></li>



  </ul>
</div>

                {/* <a className="nav-link" href="#">Services</a> */}
              </li>
              <li className="nav-item px-2">
                <Link to="/about" className="nav-link" >About Us</Link>
              </li>
              <li className="nav-item px-2">
                <Link to="/contact" className="nav-link" >Contact Us</Link>
              </li>
              </ul>
          </div>
        </div>
      </nav>
      </div>
      </section>
    </>
  );
}

export default Navbar;
