import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../Components/Home/footer/Footer'
import Navbar from '../../Components/Home/Navbar/Navbar'

function About() {
  return (
<>
<Navbar/>
<div className='container-fluit mt-5' >
<div className='row' >
<div className='col-md-6 p-5'>
<img className='founder' src={require("../../Assets/C WORLD, Website, Founder & CEO.png")} style={{maxHeight:"500px",marginTop:"-110px"}}/>
</div>
<div className='col-md-6 p-4'>
<h5 class="card-title mb-1" style={{fontWeight:"1000"}}>Message </h5>
<p style={{textAlign:"justify"}}>Welcome to C WORLD<br/>
I recognized a common challenge faced by people in their search of different IT Services, the hassle of dealing with multiple providers. In response, I've created a powerful solution and founded C WORLD on October 15, 2019.
At C WORLD, we understand the diverse needs of our client. Whether you find yourself requiring top-of-the-line hardware solutions or exploring the latest innovations in software services, our all-encompassing approach ensures that you won't need to look any further. 

Our primary objective is to enhance your overall experience by providing a one-stop solution for all your IT requirements. We've meticulously designed our services to eliminate the complexities associated with dealing with multiple providers. Our commitment goes beyond mere satisfaction; we strive to surpass your expectations.

Our dedication to making your experience as seamless as possible is reflected in our streamlined processes and customer-centric approach. By choosing C WORLD, you're not just opting for a service, you're embracing a commitment to excellence. </p>
</div>
</div>
</div>
<div style={{display:"flex"}} className='row  flex-wrap'>
<div className="fortab col-md-6 p-4">
<h5 class="card-title mb-1"style={{fontWeight:"1000",textAlign:"justify"}} >Vision</h5>
<p class="card-text" style={{textAlign:"justify"}}>Our Vision is to emerge as the forefront leader among IT companies, setting the benchmark for excellence that resonates with a vast and dedicated following. We aspire to distinguish ourselves through pioneering innovations, unparalleled services, and unwavering commitment to customer satisfaction, establishing our position as the premier choice in the realm of technology. </p>


  {/* <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/>
  <div class="card-body">
    <h5 class="card-title"style={{fontWeight:"1000"}} >Vision</h5>
    <p class="card-text" style={{alignItems:"center"}}>Our Vision is to emerge as the forefront leader among IT companies, setting the benchmark for excellence that resonates with a vast and dedicated following. We aspire to distinguish ourselves through pioneering innovations, unparalleled services, and unwavering commitment to customer satisfaction, establishing our position as the premier choice in the realm of technology. </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#visualdesigne" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div> */}
  </div>
<div className="tabing1 col-md-6 p-4" id="mission1">
<h5  class="card-title mb-1" style={{fontWeight:"1000",textAlign:"justify"}}>Mission </h5>
<p  class="card-text" style={{textAlign:"justify"}}>Our mission is to innovate and unify diverse IT services for an exceptional customer experience. Our global presence includes Software Development, Website Development, and Application Development, Graphic Designing, and Digital Marketing. Locally in Pakistan, we specialize in Access Control System, CCTV Surveillance, and Networking for robust security solutions.</p>
  {/* <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/>
  <div class="card-body">
    <h5 class="card-title" style={{fontWeight:"1000"}}>Mission </h5>
    <p class="card-text" style={{alignItems:"center"}}>Our mission is to innovate and unify diverse IT services for an exceptional customer experience. Our global presence includes Software, Website, and Application Development, Graphic Designing, and Digital Marketing. Locally in Pakistan, we specialize in Access Control, CCTV Surveillance, and Networking for robust security solutions.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#visualdesigne" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div> */}
  </div>
</div>

<Footer/>
</>
  )
}

export default About