import React from 'react'
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'

function Hospitalmangment() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
<div class="modal-content">
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
  <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Hospital Managment Software</h3>

<p style={{textAlign:"justify"}}>Streamline your healthcare operations with our cutting-edge Hospital Management Software  designed to enhance efficiency, improve patient care, and elevate overall healthcare administration.</p>

<p><strong>Key Features</strong></p>

<ol>

<p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Patient Information Management </p>


<p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Appointment Scheduling </p>



<p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Billing and Invoicing</p>



<p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Electronic Health Records (EHR)</p>



<p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Mobile Access</p>

</ol>

{/* <p><strong>Benefits That Matter</strong></p> */}

{/* <ul>

<p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Patient Care</strong> </p>



<p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Operational Efficiency</strong></p>



<p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Data Security</strong></p>



<p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Scalability</strong></p>

</ul> */}
<p style={{textAlign:"justify"}}>Experience the benefits of our Hospital Management Software, including reduced paperwork, enhanced patient experiences, error-free administration, decreased turnaround time, and cost-effectiveness for a more streamlined and efficient healthcare operation.</p>
{/* <p><strong>Why Us?</strong></p> */}

{/* <ul>

<p><strong>Easy to Use</strong><br/> No tech headaches here. Our interface is designed with you in mind, making it a breeze for your team to get the hang of things.</p>



<p><strong>Support that Cares</strong><br/> Need help? Our support team is here for you, ready to assist with any questions or issues.</p>

</ul> */}

<p style={{textAlign:"justify"}}>Transform your healthcare facility with our Hospital Management Software, a comprehensive solution for modern healthcare administration. Contact us to schedule a personalized demonstration tailored to your institution's needs.</p>



</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
    <button type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}} onClick={Whatsapp}>Whatsapp</button>
  </div>
</div>
</div>
</>
  )
}

export default Hospitalmangment