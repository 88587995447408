import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function SecurityGates() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>𝐒𝐦𝐚𝐫𝐭 𝐒𝐞𝐜𝐮𝐫𝐢𝐭𝐲 𝐆𝐚𝐭𝐞𝐬</h3>

<p style={{textAlign:"justify"}}>In an era where security and convenience are paramount, we proudly present our cutting-edge Smart Security Gates service. More than just a barrier, our innovative gates redefine traditional access control, seamlessly blending advanced technology with robust security features.</p>

<p><strong>Key Features</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Biometric Recognition</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Smart Cards/Key Fobs </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Automatic Gate Operation</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Alarm Integration</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Energy Efficiency</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Smart Security Gates enhance security by providing a robust physical barrier, deterring intruders, and offering convenient, tech-driven entry for authorized individuals, reducing reliance on traditional methods. Smart Security Gates integrate advanced features like RFID cards, ensuring secure and convenient access.
</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}> Contact us today to experience cutting-edge security solutions tailored to your needs. Your safety is our priority.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default SecurityGates