import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function LanNetwork() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Local Area Network  </h3>

<p style={{textAlign:"justify"}}>Enhance your business connectivity and efficiency with our Local Area Network (LAN)   service. Designed for seamless communication and collaboration, our Local Area Network solution empowers your organization with robust networking capabilities tailored to your specific needs.</p>

<p><strong>Key Features</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Load Balancing
</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Security Protocols  </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Network Monitoring </p>
   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>High Speed Connectivity </p>
   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Centralized Resource Sharing </p>




</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Experience heightened productivity through streamlined communication and resource sharing, fostering a collaborative work environment. Our Local Area Network service ensures cost efficiency, reliability, and customization, providing a stable infrastructure that adapts to your unique business requirements.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Elevate your business operations with our Local Area Network service, where connectivity meets efficiency for sustained success.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}} onClick={Whatsapp}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default LanNetwork