import React from 'react'
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'

function Pointofsale() {
  return (
    <>
     <div class="modal-dialog  modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Point of Sale System</h3>

<p style={{textAlign:"justify"}}>Step into the future of your business with our user-friendly Point of Sale (POS) Software! Imagine a system that not only simplifies transactions but also takes your inventory management to the next level. Here's why our POS service is a game-changer.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Sales and Receiving Management</p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Stock Handling </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Customer Management</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Supplier Management</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Report Handling</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Point of Sale (POS) Software streamlines transactions, manages inventory in real-time, and provides invaluable insights through sales analytics. It enhances efficiency, improves customer service, and ensures accurate financial tracking, contributing to overall business growth and customer satisfaction.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Ready to take your business to new heights? Our POS Software is here to make it happen. Embrace innovation, simplify your operations, and give your customers an unforgettable checkout experience. Elevate your business  one sale at a time!</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}} onClick={Whatsapp}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Pointofsale