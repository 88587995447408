import React from 'react'
import Navbar from '../../Components/Home/Navbar/Navbar'
import Footer from '../../Components/Home/footer/Footer'
import CctvComp from '../../Components/CCTVComp/CctvComp'

function Cctv() {
  return (
<>
<Navbar/>
<CctvComp/>
<Footer/>

</>
  )
}

export default Cctv