import React from 'react'
import "./mainProduct.css"
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'
function MainProduct() {
  return (
    <>
      <section className='mt-4' style={{ paddingTop: "1px", paddingBottom: "50px" }}>
        <h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E", marginTop: "40px" }}
        >
          Trending Packages
        </h1>
        <p style={{ textAlign: "center" }}>
          Explore our Social Media Marketing packages.
        </p>
        <div className="wrapper">
          <div className="single-card" style={{}}>

            <div className="info pt-4">
              <h5 style={{ color: "#3B393D", fontWeight: "1000" }}>Trial</h5>
              <p className="price mb-2 mt-3" style={{ backgroundColor: "#D66E1E", color: "white", padding: "0px", marginLeft: "-10px", width: "115%" }}> <span style={{ fontSize: "10px" }} className='rsfortop'>Rs </span>2,999<span style={{ fontSize: "10px" }}> / Week</span></p>
              <p className='mt-4' style={{ textAlign: "left", fontSize: "11px", color: "black" }}>
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation & Handling <br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Profile & Cover Photo Design<br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Social Media Posts (02)<br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Likes & Followers (0.3k Superficial)<br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation on Platforms (Facebook / <br />
                <i class="fa-solid fa-check px-2" style={{ color: "#ffff" }}></i>Instagram)<br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Ad Campaign (Facebook)<br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Reach (15k+ Estimated)
                <br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Leads (05+ Estimated)<br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Visually Appealing Graphics.<br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Researched Hashtags <br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>End of Term Analytics Report<br />
                <i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Customer Support
<br />



              </p>
              <button className=" mt-2" style={{ textDecoration: "none", border: "none", backgroundColor: "#D66E1E", color: "white", width: "100px", height: "40px", borderRadius: "100px" }} onClick={Whatsapp}>Buy Now</button>
            </div>


          </div>
          <div className="single-card" style={{}}>

            <div className="info pt-4">
              <h5 style={{ color: "#3B393D", fontWeight: "1000" }}>Starter</h5>
              {/* <h5 style={{color:"white"}}>Premium</h5> */}
              <p className="price mb-2 mt-3" style={{ backgroundColor: "#D66E1E", color: "white", padding: "0px", marginLeft: "-10px", width: "115%" }}> <span style={{ fontSize: "10px" }} className='rsfortop'>Rs</span>9,999<span style={{ fontSize: "10px" }}>/Month</span></p>

              {/* <h5 style={{backgroundColor:"#D66E1E",color:"white",borderRadius:"20px"}}>Premium</h5> */}

              {/* <p className="price mb-2" style={{color:"#423F3F"}}> <span style={{fontSize:"10px"}} className='rsfortop'>Rs</span>49,999<span style={{fontSize:"10px"}}>/m</span></p>  */}


              <p className='mt-4' style={{ textAlign: "left", fontSize: "11px", color: "black" }}>

<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation & Handling<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Profile & Cover Photo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Social Media Posts (05)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Likes & Followers (01k Superficial)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation on Platforms (Facebook / <br />
                <i class="fa-solid fa-check px-2" style={{ color: "#ffff" }}></i>Instagram)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Ad Campaign (Facebook)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Reach (50k+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Leads (20+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Visually Appealing Graphics.<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Researched Hashtags<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>End of Term Analytics Report<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Customer Support<br />
</p>

              <button className=" mt-2" style={{ textDecoration: "none", border: "none", backgroundColor: "#D66E1E", color: "white", width: "100px", height: "40px", borderRadius: "100px" }} onClick={Whatsapp}>Buy Now</button>
            </div>


          </div>
          <div className="single-card">

            <div className="info pt-4">
              <h5 style={{ color: "#3B393D", fontWeight: "1000" }}>Regular</h5>
              {/* <h5 style={{color:"white"}}>Premium</h5> */}
              <p className="price mb-2 mt-3" style={{ backgroundColor: "#D66E1E", color: "white", padding: "0px", marginLeft: "-10px", width: "115%" }}> <span style={{ fontSize: "10px" }} className='rsfortop'>Rs</span>24,999<span style={{ fontSize: "10px" }}>/Month</span></p>

              {/* <h5 style={{backgroundColor:"#D66E1E",color:"white",borderRadius:"20px"}}>Premium</h5> */}

              {/* <p className="price mb-2" style={{color:"#423F3F"}}> <span style={{fontSize:"10px"}} className='rsfortop'>Rs</span>49,999<span style={{fontSize:"10px"}}>/m</span></p>  */}


              <p className='mt-4' style={{ textAlign: "left", fontSize: "11px", color: "black" }}>

<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation & Handling<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Logo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Profile & Cover Photo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Social Media Posts (15)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Likes & Followers (2.5k Superficial)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation on Platforms (Facebook / <br />
                <i class="fa-solid fa-check px-2" style={{ color: "#ffff" }}></i>Instagram)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Ad Campaign (Facebook)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Reach (150k+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Leads (50+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Visually Appealing Graphics.<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Researched Hashtags<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>End of Term Analytics Report<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Customer Support<br />
</p>

              <button className=" mt-2" style={{ textDecoration: "none", border: "none", backgroundColor: "#D66E1E", color: "white", width: "100px", height: "40px", borderRadius: "100px" }} onClick={Whatsapp}>Buy Now</button>
            </div>


          </div>



        </div>
        <div className="wrapper">
          <div className="single-card" style={{}}>

            <div className="info pt-4">
              <h5 style={{ color: "#3B393D", fontWeight: "1000" }}>Standard</h5>
              {/* <h5 style={{color:"white"}}>Premium</h5> */}
              <p className="price mb-2 mt-3" style={{ backgroundColor: "#D66E1E", color: "white", padding: "0px", marginLeft: "-10px", width: "115%" }}> <span style={{ fontSize: "10px" }} className='rsfortop'>Rs </span>49,999<span style={{ fontSize: "10px" }}>/Month</span></p>

              {/* <h5 style={{backgroundColor:"#D66E1E",color:"white",borderRadius:"20px"}}>Premium</h5> */}

              {/* <p className="price mb-2" style={{color:"#423F3F"}}> <span style={{fontSize:"10px"}} className='rsfortop'>Rs</span>49,999<span style={{fontSize:"10px"}}>/m</span></p>  */}


              <p className='mt-4' style={{ textAlign: "left", fontSize: "11px", color: "black" }}>

<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation & Handling<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Logo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Profile & Cover Photo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Social Media Posts (30)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Likes & Followers (06k Superficial)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation on Platforms (Facebook / <br />
                <i class="fa-solid fa-check px-2" style={{ color: "#ffff" }}></i>Instagram)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Ad Campaign (Facebook)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Reach (500k+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Leads (120+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Visually Appealing Graphics.<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Researched Hashtags<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Competitors Analysis<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Dedicated Account Manager<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>End of Term Analytics Report<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Customer Support<br />
</p>

              <button className=" mt-2" style={{ textDecoration: "none", border: "none", backgroundColor: "#D66E1E", color: "white", width: "100px", height: "40px", borderRadius: "100px" }} onClick={Whatsapp}>Buy Now</button>
            </div>


          </div>
          <div className="single-card" style={{}}>

            <div className="info pt-4">
              <h5 style={{ color: "#3B393D", fontWeight: "1000" }}>Premium</h5>
              {/* <h5 style={{color:"white"}}>Premium</h5> */}
              <p className="price mb-2 mt-3" style={{ backgroundColor: "#D66E1E", color: "white", padding: "0px", marginLeft: "-10px", width: "115%" }}> <span style={{ fontSize: "10px" }} className='rsfortop'>Rs</span>1,30,000<span style={{ fontSize: "10px" }}>/Month</span></p>

              {/* <h5 style={{backgroundColor:"#D66E1E",color:"white",borderRadius:"20px"}}>Premium</h5> */}

              {/* <p className="price mb-2" style={{color:"#423F3F"}}> <span style={{fontSize:"10px"}} className='rsfortop'>Rs</span>49,999<span style={{fontSize:"10px"}}>/m</span></p>  */}


              <p className='mt-4' style={{ textAlign: "left", fontSize: "11px", color: "black" }}>

<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation & Handling<br />

<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Logo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Profile & Cover Photo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Social Media Posts (Unlimited)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Likes & Followers (15k Superficial)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation On Platforms (Facebook / <br />
<i class="fa-solid fa-check px-2" style={{ color: "#ffff" }}></i>Instagram / YouTube / Tiktok / Twitter)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Listing On Google (Google My Business Profile)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Ad Campaign (Facebook)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Reach (1.5M+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Leads (270+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Visually Appealing Graphics.<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Researched Hashtags<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Competitors Analysis<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Dedicated Account Manager<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>End of Term Analytics Report<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Customer Support<br />

</p>

              <button className=" mt-2" style={{ textDecoration: "none", border: "none", backgroundColor: "#D66E1E", color: "white", width: "100px", height: "40px", borderRadius: "100px" }} onClick={Whatsapp}>Buy Now</button>
            </div>


          </div>
          <div className="single-card">

            <div className="info pt-4">
              <h5 style={{ color: "#3B393D", fontWeight: "1000" }}>Ultimate</h5>
              {/* <h5 style={{color:"white"}}>Premium</h5> */}
              <p className="price mb-2 mt-3" style={{ backgroundColor: "#D66E1E", color: "white", padding: "0px", marginLeft: "-10px", width: "115%" }}> <span style={{ fontSize: "10px" }} className='rsfortop'>Rs</span>1,70,000<span style={{ fontSize: "10px" }}>/Month</span></p>

              {/* <h5 style={{backgroundColor:"#D66E1E",color:"white",borderRadius:"20px"}}>Premium</h5> */}

              {/* <p className="price mb-2" style={{color:"#423F3F"}}> <span style={{fontSize:"10px"}} className='rsfortop'>Rs</span>49,999<span style={{fontSize:"10px"}}>/m</span></p>  */}


              <p className='mt-4' style={{ textAlign: "left", fontSize: "11px", color: "black" }}>

<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation & Handling<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Logo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Profile & Cover Photo Design<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Social Media Posts (Unlimited)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Commercial Video Ad (01)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Likes & Followers (15k Superficial)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Accounts Creation On Platforms (Facebook / <br />
<i class="fa-solid fa-check px-2" style={{ color: "#ffff" }}></i>Instagram / YouTube / Tiktok / Twitter)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Listing On Google (Google My Business Profile)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Ad Campaign (Facebook)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Reach (1.5M+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Facebook Page Leads (270+ Estimated)<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Visually Appealing Graphics.<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Researched Hashtags<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Competitors Analysis<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Dedicated Account Manager<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>End of Term Analytics Report<br />
<i class="fa-solid fa-check px-2" style={{ color: "#D66E1E" }}></i>Customer Support<br />

</p>

              <button className=" mt-2" style={{ textDecoration: "none", border: "none", backgroundColor: "#D66E1E", color: "white", width: "100px", height: "40px", borderRadius: "100px" }} onClick={Whatsapp}>Buy Now</button>
            </div>


          </div>











          {/* <div className="single-card">
    <div className="img-area">
    <img className='mt-2' src={require("../../Assets/apple-watch-series-2-apple-watch-series-3-pebble-apple-white-smart-watch-01ff88ffc31fe352126b1b179311afb2.png")} alt="" />
  
    </div>
    <div className="info">
      <h3>Laptop</h3>
      <p className="price">$599.99</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing</p>
      <button className="btn btn-success" onClick={Whatsapp}>Buy Now</button>
    </div>


    
   

  </div>
  <div className="single-card">
    <div className="img-area">
    <img className='mt-2' src={require("../../Assets/apple-watch-series-2-apple-watch-series-3-pebble-apple-white-smart-watch-01ff88ffc31fe352126b1b179311afb2.png")} alt="" />
   
    </div>
    <div className="info">
      <h3>Headphone</h3>
      <p className="price">$29.99</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing</p>
      <button className="btn btn-success" onClick={Whatsapp}>Buy Now</button>
    </div>
  </div> */}

        </div>
      </section>

    </>
  )
}

export default MainProduct