import React from 'react'
import ApplicationComp from '../../Components/ApplicationComp/ApplicationComp'
import Navbar from '../../Components/Home/Navbar/Navbar'
import Footer from '../../Components/Home/footer/Footer'

function ApplicationDev() {
  return (
<>
<Navbar/>

<ApplicationComp/>
<Footer/>
</>
  )
}

export default ApplicationDev