import React from 'react'
import Ads from '../../Components/Home/Ads'
import Salider from '../../Components/Home/Salider'
import Navbar from '../../Components/Home/Navbar/Navbar'
import CardsSection from '../../Components/Home/CardsSection'
import Footer from '../../Components/Home/footer/Footer'
// import MainProduct from '../../Components/MainProducts/MainProduct'
import ContactUs from '../../Components/Home/ContactUs/ContactUs'
import Avi from '../../Components/Home/Avi'
// import OurProducts from '../Components/Home/OurProducts'

function Home() {
  return (
    <>
{/* <Avi/> */}
    <Navbar/>
    <Salider/>
  
    <CardsSection/>
    {/* <OurProducts/> */}
    {/* <MainProduct/> */}
    <ContactUs/>
    <Footer/>
    </>
  )
}

export default Home