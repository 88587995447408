import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function Costmizedweb() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Agile/Customized Website </h3>

<p style={{textAlign:"justify"}}>Embark on an innovative journey with our tailored website solutions, redefining digital excellence for your business. Beyond a standard website, we provide a strategic advantage, seamlessly aligning with your goals and adapting to evolving requirements. Our dedicated team crafts experiences that resonate with your audience, fostering engagement and loyalty. Thrive in the dynamic digital landscape with our forward-thinking approach, staying ahead of trends and technologies. Connect with us for a transformative online presence, strategically elevating your business.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Costmizedweb