import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function EmailMarketing() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Email Marketing </h3>

<p style={{textAlign:"justify"}}>Welcome to our Email Marketing service, a potent tool in the digital marketing arsenal. Email Marketing involves sending targeted messages to a group of recipients via email, offering a direct and personalized approach to engage with your audience.</p>

<p><strong>Top Platforms </strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>MailChimp
</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Constant Contact  </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Send Grid</p>




</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Forge direct communication with your audience, fastering engagement and brand loyalty through our Email Marketing service. Nurture leads with personalized content, guiding them seamlessly through the sales funnel. Achieve cost-effectiveness and measurable results, utilizing analytics for continuous improvement. Tailor messages with segmentation and personalization, ensuring relevance and effectiveness in every campaign.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Leverage Email Marketing to build connections, drive engagement, and achieve measurable results. Our service ensures resonant messages, fostering lasting relationships with your audience.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default EmailMarketing