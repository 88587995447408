import React from 'react'
import "../Home/OurProduct.css";
import VisualDesigne from './VisualDesigne/VisualDesigne';
import Uiux from './UiuxDesigne/Uiux';
import VideoEditing from './VedioEditing/VideoEditing';

function GraphicComp() {
  return (
    <>
      <div className="container-fluid mt-3  flex-wrap mb-5" >
        <h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}
        >
Graphic Designing
        </h1>
        <p style={{ textAlign: "center" }}>
        Unlock creativity, elevate your brand with tailored graphic design services.
         </p>

<div className="row mt-3" style={{display:"flex",justifyContent:"center"}} >
  <div className="fortab col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Visual Design</h5>
    <p class="card-text" style={{alignItems:"center"}}>Step into our full range of Visual Design services, carefully tailored to enhance and strengthen your brand identity </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#visualdesigne" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >UI/UX Design </h5>
    <p class="card-text" style={{alignItems:"center"}}>UI/UX design, as a category within graphic design, focuses on enhancing the user experience.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#uiuxdesigne" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Vedio Editing</h5>
    <p class="card-text" style={{alignItems:"center"}}>Our Video Editing Service where we specialize in transforming raw footage into compelling visual stories. </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#videoediting" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>

  </div>


      </div>




{/* modal */}



{/* <!-- Modal --> */}
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="visualdesigne" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<VisualDesigne/>
</div>


<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="uiuxdesigne" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<Uiux/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="videoediting" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<VideoEditing/>
</div>



    </>
  )
}

export default GraphicComp