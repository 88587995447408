import React from 'react'
import Navbar from '../Home/Navbar/Navbar'
import Footer from '../Home/footer/Footer'
import MainProduct from '../MainProducts/MainProduct'

function TrendingPackage() {
  return (
<>

<Navbar/>
<MainProduct/>

<Footer/>

</>
  )
}

export default TrendingPackage