import React from 'react'
import Navbar from '../../Components/Home/Navbar/Navbar'
import Footer from '../../Components/Home/footer/Footer'
import AccessControlComp from '../../Components/AccessControlComp/AccessControlComp'

function AccessControl() {
  return (
<>
<Navbar/>
<AccessControlComp/>
<Footer/>

</>
  )
}

export default AccessControl