import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function Ecommerceweb() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>E-commerce Website  </h3>

<p style={{textAlign:"justify"}}>Unlock the potential of your online business with our E-commerce Website development services. We specialize in creating tailored solutions that seamlessly blend innovation and functionality, ensuring your digital storefront stands out in the ever-evolving online landscape.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Mobile Optimized Design
 </p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Product Showcase </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Secure Shopping Cart</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Inventory Management</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Order Tracking</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Data-driven Decision Making</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Secure and Scalable</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong></p>

</ul> */}
<p style={{textAlign:"justify"}}>Elevate your brand credibility and boost sales with our E-commerce Website development services. We prioritize user satisfaction, ensuring your online platform not only meets but exceeds customer expectations. Our solutions provide a cost-effective and time-efficient way to establish and grow your online presence. From increased sales to enhanced brand reputation, C World is your partner in achieving digital success.</p>
{/* <p><strong>Why Us?</strong></p> */}

{/* <ul>

    <p><strong>Easy to Use</strong><br/> No tech headaches here. Our interface is designed with you in mind, making it a breeze for your team to get the hang of things.</p>



    <p><strong>Support that Cares</strong><br/> Need help? Our support team is here for you, ready to assist with any questions or issues.</p>

</ul> */}

<p style={{textAlign:"justify"}}>Ready to transform your E-commerce vision into a reality? Contact us to get started.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Ecommerceweb