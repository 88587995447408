import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function Uiux() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>UI/UX Design </h3>

<p style={{textAlign:"justify"}}>UI/UX Design, as a category within graphic design, focuses on enhancing the user experience and interface of digital products. It combines artistic and functional elements to create visually appealing and user-friendly interactions.</p>

<p><strong>Top Categories</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Websites
</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Softwares </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Mobile Applications</p>




</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Our UI/UX Design service offers a dual benefit, integrating both User Interface (UI) and User Experience (UX) to elevate your digital presence. A thoughtfully designed UI enhances the visual appeal and intuitiveness of your platform, while UX ensures a positive overall experience by understanding and addressing user needs. Our service not only enhances brand perception but also ensures a competitive edge in the digital realm, making your user's journey a harmonious and memorable experience.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Empower your digital presence with our expert UI/UX Design, where thoughtful interfaces meet seamless user experiences. Let's transform your platform into a captivating and user-friendly space together.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default Uiux