import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function PanNetwork() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Personal Area Network </h3>

<p style={{textAlign:"justify"}}>Step into the realm of personalized connectivity with our Personal Area Network (PAN) service. Tailored for individual users, Personal Area Network  creates a seamless environment for connecting personal devices within close proximity, revolutionizing the way you experience and share digital information.</p>

<p><strong>Key Features</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Secure File Sharing
</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Multi Device Control  </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Bluetooth Integration </p>
   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Device Interconnectivity </p>
   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Real Time Communication  </p>




</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}> Revel in enhanced convenience by effortlessly managing devices in close proximity, streamlining your daily digital interactions. Embrace newfound mobility through wireless connectivity, staying connected without physical constraints, while data efficiency optimizes device use for an enhanced digital experience.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Elevate your digital experience with these advanced features, tailored to meet the demands of a connected and personalized lifestyle.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}} onClick={Whatsapp}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default PanNetwork