import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
function CardsSection() {
  return (
    <>
      {/* <div className='mt-4 px-5'>
    <h4 className='bold' style={{fontWeight:"1000"}}>Our New Services</h4>
    <p> The href attribute requires a valid value to be accessible <br/>valid value to be accessible</p>
    </div> */}
      <div className="card-container container-fluid mt-1 ">
        <h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}
        >
          Our Services
        </h1>
        <p style={{ textAlign: "center" }}>
        Transforming businesses through cutting-edge IT solutions for a digital future.
        </p>
        <div className="row mt-3">
        <div className="col-md-12 d-flex flex-wrap justify-content-center">
        <Link to="/software-development" style={{textDecoration:"none"}}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              
              }}
              className="cardnew"
            >
              <img
                src={require("../../Assets/ww (1).png")}
                class="card-img-top forhover"
                alt="..."
                style={{ maxHeight: "160px", maxWidth: "160px" ,marginBottom:"-10px"}}
              />
              <div className="cardheadingdiv" style={{textAlign:"center"}}>
              <h6 className="cardheading" style={{marginBottom:"-5px",color:"#423F3F"}}>Software</h6>
              <h6 className="cardheading" style={{color:"#807F7E",marginTop:"5px"}}>Development</h6>
              </div>
             
              {/* <h6 className="cardheading mb-5 " style={{textAlign:"center"}}> <span className="" style={{color:"#423F3F"}}>Software </span><br/> <span style={{color:"#807F7E",marginTop:"5px"}}>Development</span></h6> */}
            </div>
            </Link>


            <div className="">
            <Link to="/website-development" style={{textDecoration:"none"}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="cardnew"
              >
                <img
                  src={require("../../Assets/ww (2).png")}
                  class="card-img-top forhover"
                  alt="..."
                  style={{ maxHeight: "160px", maxWidth: "160px",marginBottom:"-10px" }}
                />
                          <div className="cardheadingdiv" style={{textAlign:"center"}}>
              <h6 className="cardheading" style={{marginBottom:"-5px",color:"#423F3F"}}>Website</h6>
              <h6 className="cardheading" style={{color:"#807F7E",marginTop:"5px"}}>Development</h6>
              </div> 
                          {/* <h6 className="cardheading mb-5"> <span className="px-4" style={{color:"#423F3F"}}>Website </span><br/> <span style={{color:"#807F7E",marginTop:"5px"}}>Development</span></h6> */}
              </div>
             </Link>
            </div>


            <div className="">
            <Link to="/application-development" style={{textDecoration:"none"}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="cardnew"
              >
                <img
                  src={require("../../Assets/ww (3).png")}
                  class="card-img-top forhover"
                  alt="..."
                  style={{ maxHeight: "160px", maxWidth: "160px",marginBottom:"-10px" }}
                />

<div className="cardheadingdiv" style={{textAlign:"center"}}>
              <h6 className="cardheading" style={{marginBottom:"-5px",color:"#423F3F"}}>Application</h6>
              <h6 className="cardheading" style={{color:"#807F7E",marginTop:"5px"}}>Development</h6>
              </div>
                        {/* <h6 className="cardheading mb-5"> <span className="px-2" style={{color:"#423F3F"}}>Application </span><br/> <span style={{color:"#807F7E",marginTop:"5px"}}>Development</span></h6> */}
              </div>
              </Link>
            </div>

          
            <div className="">
            <Link to="/access-control-system" style={{textDecoration:"none"}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="cardnew"
              >
                <img
                  src={require("../../Assets/ww (4).png")}
                  class="card-img-top forhover"
                  alt="..."
                  style={{ maxHeight: "160px", maxWidth: "160px",marginBottom:"-10px" }}
                />   
                <div className="cardheadingdiv" style={{textAlign:"center"}}>
                <h6 className="cardheading" style={{marginBottom:"-5px",color:"#423F3F"}}>Access Control</h6>
                <h6 className="cardheading" style={{color:"#807F7E",marginTop:"5px"}}>System</h6>
                </div>
                           {/* <h6 className="cardheading mb-5"> <span className="px-4" style={{color:"#423F3F"}}>Access </span><br/> <span style={{color:"#807F7E",marginTop:"5px"}}>control systems</span></h6> */}
              </div>
              </Link>
            </div>

           
            <div className="">
            <Link to="/cctv-surveillance" style={{textDecoration:"none"}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="cardnew"
              >
                <img
                  src={require("../../Assets/ww (5).png")}
                  class="card-img-top forhover"
                  alt="..."
                  style={{ maxHeight: "160px", maxWidth: "160px",marginBottom:"-10px" }}
                />
                  <div className="cardheadingdiv" style={{textAlign:"center"}}>
                <h6 className="cardheading" style={{marginBottom:"-5px",color:"#423F3F"}}>CCTV</h6>
                <h6 className="cardheading" style={{color:"#807F7E",marginTop:"5px"}}>Surveillance</h6>
                </div>
        {/* <h6 className="cardheading mb-5"> <span className="px-5" style={{color:"#423F3F"}}>CCTV </span><br/> <span style={{color:"#807F7E",marginTop:"5px"}}>control Surveillance</span></h6> */}
              </div>
              </Link>
            </div>

            <div className="">
            <Link to="/graphic-designing" style={{textDecoration:"none"}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="cardnew"
              >
                <img
                  src={require("../../Assets/ww (6).png")}
                  class="card-img-top forhover"
                  alt="..."
                  style={{ maxHeight: "160px", maxWidth: "160px" ,marginBottom:"-10px"}}
                />
                                  <div className="cardheadingdiv" style={{textAlign:"center"}}>
                <h6 className="cardheading" style={{marginBottom:"-5px",color:"#423F3F"}}>Graphic</h6>
                <h6 className="cardheading" style={{color:"#807F7E",marginTop:"5px"}}>Designing</h6>
                </div>
               {/* <h6 className="cardheading mb-5"> <span className="px-1" style={{color:"#423F3F"}}>Graphic </span><br/> <span style={{color:"#807F7E",marginTop:"5px"}}>Designing</span></h6> */}
              </div>
              </Link>
            </div>

   
            <div className="">
            <Link to="/digital-marketing" style={{textDecoration:"none"}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="cardnew"
              >
                <img
                  src={require("../../Assets/ww (7).png")}
                  class="card-img-top forhover"
                  alt="..."
                  style={{ maxHeight: "160px", maxWidth: "160px",marginBottom:"-10px" }}
                />
                                <div className="cardheadingdiv" style={{textAlign:"center"}}>
                <h6 className="cardheading" style={{marginBottom:"-5px",color:"#423F3F"}}>Digital</h6>
                <h6 className="cardheading" style={{color:"#807F7E",marginTop:"5px"}}>Marketing</h6>
                </div>
                 {/* <h6 className="cardheading mb-5"> <span className="px-2" style={{color:"#423F3F"}}>Digital  </span><br/> <span style={{color:"#807F7E",marginTop:"5px"}}>Marketing</span></h6> */}
              </div>
              </Link>
            </div>


            <div className="">
            <Link to="/networking" style={{textDecoration:"none"}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="cardnew"
              >
                <img
                  src={require("../../Assets/ww (8).png")}
                  class="card-img-top forhover"
                  alt="..."
                  style={{ maxHeight: "160px", maxWidth: "160px",marginBottom:"-10px" }}
                />
                         <div className="cardheadingdiv" style={{textAlign:"center"}}>
                <h6 className="cardheading" style={{marginBottom:"-5px",color:"#423F3F"}}>Networking </h6>
                {/* <h6 className="cardheading" style={{color:"#807F7E",marginTop:"5px"}}>Marketing</h6> */}
                </div>
                    {/* <h6 className="cardheading mb-5"> <span className="px-3" style={{color:"#423F3F"}}>Networking  </span></h6> */}
              </div>
              </Link>
            </div>

          </div>
        </div>
     
      </div>
    </>
  );
}

export default CardsSection;
