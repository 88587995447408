import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function EsSecurity() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>EAS Security Antennas</h3>

<p style={{textAlign:"justify"}}>Upgrade the security infrastructure of your brand with our EAS Security Antennas, providing a robust and effective solution for loss prevention and asset protection.</p>

<p><strong>Key Features </strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Standard Frequency Range Detection</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Adjustable Sensitivity </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Alarm Integration</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Energy Efficiency</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Real Time Alerts</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>EAS Security Antennas are a proactive deterrent against theft, enhancing the shopping environment's safety. With real-time monitoring capabilities, they enable prompt responses to security threats, contributing to a secure and positive customer experience. Their versatile placement ensures adaptability throughout the shopping mall, providing an integrated and comprehensive security solution.
</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Secure your assets with EAS Security Antennas. Experience peace of mind with our advanced security technology.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default EsSecurity