import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function WirelessCamera() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Wireless Cameras </h3>

<p style={{textAlign:"justify"}}>Welcome to the future of surveillance with our Wireless Cameras. These innovative devices redefine the landscape of security monitoring, providing flexibility and convenience through wireless connectivity.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Wireless Connectivity</p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>360 ° Coverage </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Motion Detection</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Mobile Alerts</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Auto Backup </p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Experience unparalleled flexibility with our Wireless Cameras, allowing easy relocation to adapt to changing surveillance needs. Effortlessly expand your security network without physical wiring constraints, reducing installation costs and disruption. These weather-resistant cameras are ideal for indoor or outdoor installations, ensuring reliable performance in diverse conditions. Upgrade to wireless technology for seamless and adaptable surveillance solutions.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Upgrade security effortlessly with our Wireless Cameras ensuring seamless surveillance tailored to your needs.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default WirelessCamera