import React from 'react'
import EmailMarketing from './EmailMarketing/EmailMarketing'
import SoialMediaMarketing from './SoialMediaMarketing/SoialMediaMarketing'
import GoogleAds from './GoogleAds/GoogleAds'
import MainProduct from '../MainProducts/MainProduct'

function DigitalMarketingComp() {
  return (
    <>
      <div className="container-fluid mt-3 mb-5 flex-wrap "  >
        <h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}>
Digital Marketing
        </h1>
        <p style={{ textAlign: "center" }}>
        Elevate online presence with precise digital marketing for growth and visibility.
         </p>
<div className="row mt-3" style={{display:"flex",justifyContent:"center"}} >
  <div className="fortab col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Email Marketing</h5>
    <p class="card-text" style={{alignItems:"center"}}>
Welcome to our Email Marketing service, a potent tool in the digital marketing arsenal.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#emailmarketing" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Social Media Marketing </h5>
    <p class="card-text" style={{alignItems:"center"}}>Welcome to our Social Media Marketing service, where we craft dynamic strategies to your brand</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#soialmediamarketing" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Google Ads</h5>
    <p class="card-text" style={{alignItems:"center"}}>Welcome to our Google Ads service, a dynamic solution for businesses aiming to maximize their online reach.  </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#googleads" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>

  </div>


      </div>


{/* <MainProduct/> */}

{/* modal */}



{/* <!-- Modal --> */}
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="emailmarketing" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<EmailMarketing/>
</div>


<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="soialmediamarketing" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<SoialMediaMarketing/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="googleads" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<GoogleAds/>
</div>



    </>
  )
}

export default DigitalMarketingComp