import React from 'react'
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'

function Pharmacy() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Pharmacy Management System </h3>

<p style={{textAlign:"justify"}}>Welcome to our Pharmacy Management System, a cutting-edge solution designed to revolutionize the way pharmacies operate. Our service is meticulously crafted to address the dynamic needs of modern pharmacies, providing a seamless and efficient platform for comprehensive management.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Sales and Receiving Management</p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Stock Handling </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Expiry Date Tracking </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Supplier Management</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Report Handling</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Our Pharmacy Management System optimizes costs through inventory efficiency, reduces wastage, and improves overall operations. With enhanced security, dedicated customer support, and robust analytics, it modernizes the pharmacy experience, empowering informed decisions and enhancing business outcomes.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Upgrade your pharmacy's efficiency with our secure and streamlined Pharmacy Management System. Your path to efficient healthcare management starts with a simple connection.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Pharmacy