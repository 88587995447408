import React from "react";
// import "./Home.css";
import "./OurProduct.css";
import Pointofsale from "../Pointofsale/Pointofsale";
import Hotelmangment from "../HotelManagment/Hotelmangment";
import Schoolmangement from "../Schoolmangment/Schoolmangement";
import Hospitalmangment from "../HospitalMangment/Hospitalmangment";
import PropertyMangment from "../PropertyManagment/PropertyMangment";
import Pharmacy from "../PharmacyMangment/Pharmacy";
import CostomizeSoftware from "../CostomizeSoftware/Costomizesoftware";
import Hardwareintegration from "../Hardwareintegration/Hardwareintegration";

function OurProducts() {
  return (
    <>
      <div className="container-fluid mt-3 mb-2 flex-wrap mb-5 "  >
        <h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}
        >
          Software Development
        </h1>
        <p style={{ textAlign: "center" }}>
       
        Drive business growth with our innovative software development services suite.
         </p>

<div className="row fortabsonly mt-3" >
  <div className="fortab col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" style={{fontSize:"19px"}} >Point of Sale Software</h5>
    <p class="card-text" style={{alignItems:"center"}}>Revolutionize business operations with our POS Software solution</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center mb-3" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" style={{fontSize:"19px"}}>Hotel Management Software</h5>
    <p class="card-text" style={{alignItems:"center"}}>Step into a new era of hospitality with our Hotel Management.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#hotelmangment" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center mb-3" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" style={{fontSize:"19px"}} >School Management Software </h5>
    <p class="card-text" style={{alignItems:"center"}}>Educational institution's efficiency with our School Mangement service.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#schoolmangment" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center mb-3" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" style={{fontSize:"19px"}} >Hospital Management Software</h5>
    <p class="card-text" style={{alignItems:"center"}}>Streamline your healthcare operations with our Hospital Management </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#hospitalmangment" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center mb-3" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" style={{fontSize:"19px"}} >Property Managment Software</h5>
    <p class="card-text" style={{alignItems:"center"}}>
Streamline property management with our user-friendly platform. </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#propertymangment" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center mb-3" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" style={{fontSize:"19px"}}>Pharmacy Managment Software</h5>
    <p class="card-text" style={{alignItems:"center"}}>
    Our service is precisely made to address the needs of pharmacies </p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#pharmacymangment" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center mb-3" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" style={{fontSize:"19px"}} >Agile/Customized software </h5>
    <p class="card-text" style={{alignItems:"center"}}>
    we specialize in delivering bespoke software solutions</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#agilecustomized" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center mb-3" style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" style={{fontSize:"19px"}}>Hardware Integration  </h5>
    <p class="card-text" style={{alignItems:"center"}}>
    Our expertise lies in seamlessly merging software and hardware.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#hardwareintegration" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  
  </div>


      </div>





{/* modal */}



{/* <!-- Modal --> */}
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Pointofsale/>
</div>


<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="hotelmangment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Hotelmangment/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="schoolmangment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Schoolmangement/>
</div>
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="hospitalmangment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Hospitalmangment/>
</div>
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="propertymangment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <PropertyMangment/>
</div>
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="pharmacymangment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Pharmacy/>
</div>
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="agilecustomized" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <CostomizeSoftware/>
</div>
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="hardwareintegration" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Hardwareintegration/>
</div>
    </>
  );
}

export default OurProducts;
