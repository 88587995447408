import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function Realestate() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Real Estate Website</h3>

<p style={{textAlign:"justify"}}>Explore the next level of Real Estate possibilities with our website development services. We create tailored online platforms, marrying innovation with seamless design. Your brand takes center stage, supported by a commitment to excellence. Step into a digital realm where your real estate venture thrives. Welcome to a new era of success with our Real Estate Website development services.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Property Listings
 </p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Interactive Maps  </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Contact Forms</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Virtual Tours</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Payment Plans</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Data-driven Decision Making</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Secure and Scalable</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong></p>

</ul> */}
<p style={{textAlign:"justify"}}>Our Real Estate Website development service caters to users by providing efficient property searches, engaging experiences, and mobile accessibility. For business owners, it ensures streamlined property management, increased online presence, scalability, and improved search engine rankings,fostering growth and success in the competitive Real Estate market.</p>
{/* <p><strong>Why Us?</strong></p> */}

{/* <ul>

    <p><strong>Easy to Use</strong><br/> No tech headaches here. Our interface is designed with you in mind, making it a breeze for your team to get the hang of things.</p>



    <p><strong>Support that Cares</strong><br/> Need help? Our support team is here for you, ready to assist with any questions or issues.</p>

</ul> */}

<p style={{textAlign:"justify"}}>Join us for a future where your real estate venture thrives in the digital realm. Choose innovation, choose success—choose us.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Realestate