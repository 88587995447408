import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function VideoEditing() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Vedio Editing</h3>

<p style={{textAlign:"justify"}}>Welcome to our Video Editing service where we specialize in transforming raw footage into compelling visual stories, employing creative expertise to enhance your content.</p>

<p><strong>Top Categories</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Commercial Ads</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Product Demonstration Videos   </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Social Media Videos </p>

  

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Elevate your content with engaging video edits that captivate and retain your audience. We maintain a consistent visual style, reinforcing your brand identity. Thoughtful edits faster longer engagement, and professional polish ensures meticulous attention to detail. Our customized edits for different platforms maximize impact, extending your reach. </p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Choose us for a compelling visual experience that leaves a lasting impression. Let your story shine with our expertise in Video Editing.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default VideoEditing