import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function VisualDesigne() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Visual Design </h3>

<p style={{textAlign:"justify"}}>Step into our full range of Visual Design services, carefully tailored to enhance and strengthen your brand identity. We're here to transform your brand vision into a standout and memorable presence, both online and offline.</p>

<p><strong>Top Categories</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Logo</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Thumbnails   </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Notebook </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Social Media Post</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Business Catalogue</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>We make sure your brand looks consistent everywhere, so people easily recognize it. Our eye-catching designs help you stand out, stay remembered, and connect with more customers. Whether you need a sleek logo or captivating social media posts, we've got your brand covered.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Let your brand shine with design that resonates. Explore the power of visual distinction with us today.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default VisualDesigne