import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function SmartDoorLock() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Smart Door Locks</h3>

<p style={{textAlign:"justify"}}>Step into a new era of security and convenience with our cutting-edge Smart Door Locks service. As technology continues to evolve, so should the way we protect our homes and businesses. Our Smart Door Locks offer a seamless blend of advanced security features and user-friendly functionality, redefining the way you control access to your space.</p>

<p><strong>Key Features</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Keyless Entry</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Temper Alerts </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Biometric Authentication</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Auto Locking/Unlocking</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Security Enhancement</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Experience the benefits of our service as we tailor solutions to your unique needs, ensuring effective smart door lock integration. Our professional team guarantees precision in installation and configuration, offering a seamless and efficient experience. Stay at the forefront of access control technology with cutting-edge features like keyless entry and remote access. Enjoy enhanced security and peace of mind, trusting in our commitment to fortifying your property with robust access control measures.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Elevate your living or working environment with the convenience and reassurance that our Smart Door Lock integration brings.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default SmartDoorLock