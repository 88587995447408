import React from 'react'
import Navbar from '../../Components/Home/Navbar/Navbar'
import Footer from '../../Components/Home/footer/Footer'
import ContactUs from '../../Components/Home/ContactUs/ContactUs'

function Contact() {
  return (
  <>
  <Navbar/>
<ContactUs/>

  <Footer/>
  
  
  </>
  )
}

export default Contact