import React from 'react'
import Navbar from '../../Components/Home/Navbar/Navbar'
import Footer from '../../Components/Home/footer/Footer'
import GraphicComp from '../../Components/GraphicComp/GraphicComp'

function Graphic() {
  return (
   <>
   <Navbar/>
<GraphicComp/>
   <Footer/>
   
   
   </>
  )
}

export default Graphic