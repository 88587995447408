import React from 'react'
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'

function CostomizeSoftware() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Agile/Customize Software  </h3>

<p style={{textAlign:"justify"}}>Discover the transformative impact of our Agile or Customize Software solutions. From efficient project management to scalable applications tailored to your unique needs, our services redefine operational excellence and foster innovation. Elevate your business with our comprehensive software solutions, meticulously crafted for success in the dynamic digital landscape.</p>





<p style={{textAlign:"justify"}}>Moreover, we specialize in delivering bespoke software solutions, precisely tailored to meet your distinct business requirements. Our dedicated team ensures seamless alignment with your strategic goals, providing not just software but a strategic advantage. Experience innovation that adapts to your evolving needs, as we create tailored solutions to propel your enterprise to new heights.</p>
{/* <p><strong>Why Us?</strong></p> */}





</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default CostomizeSoftware