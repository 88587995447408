import React from 'react'
import Navbar from '../../Components/Home/Navbar/Navbar'
import Footer from '../../Components/Home/footer/Footer'
import Websitecomp from '../../Components/WebsiteComp/Websitecomp'

function Webdev() {
  return (
<>
<Navbar/>

<Websitecomp/>
<Footer/>

</>
  )
}

export default Webdev