import React from 'react'

function Whatsapp() {

  const phoneNumber = '+923407460699';
  const message = 'Hi, I recently visited C WORLD  Website. Can you please assist me with my inquiry? Thanks!';
  const encodedMessage = encodeURIComponent(message);
  const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(url, '_blank');
      }
      
 


export default Whatsapp