import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function Educatinalweb() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Educational Website</h3>

<p style={{textAlign:"justify"}}>Unlocking Educational excellence in the digital era.
Empower your educational institution with our comprehensive website development services, designed to enhance the learning experience and faster academic growth.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Learning Management System (LMS)
 </p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Updates/News Alerts  </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Virtual Classrooms</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Discussion Forums</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Resource Libraries</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Data-driven Decision Making</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Secure and Scalable</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong></p>

</ul> */}
<p style={{textAlign:"justify"}}>Our Educational Website development ensures tailored learning experiences, 24/7 access to resources for self-paced learning, and seamless collaboration tools for educators. Dedicated Parent-Teacher Portals enhance communication and transparency, while secure blockchain credentials validate academic achievements. Virtual laboratories offer dynamic, practical exploration in a digital environment.</p>
{/* <p><strong>Why Us?</strong></p> */}

{/* <ul>

    <p><strong>Easy to Use</strong><br/> No tech headaches here. Our interface is designed with you in mind, making it a breeze for your team to get the hang of things.</p>



    <p><strong>Support that Cares</strong><br/> Need help? Our support team is here for you, ready to assist with any questions or issues.</p>

</ul> */}

<p style={{textAlign:"justify"}}>Contact us today to embark on a transformative journey towards Digital Education excellence.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Educatinalweb