import React from 'react'
import Navbar from '../../Components/Home/Navbar/Navbar'
import Footer from '../../Components/Home/footer/Footer'
import NetworkingComp from '../../Components/NetworkingComp/NetworkingComp'

function Networking() {
  return (
<>
<Navbar/>
<NetworkingComp/>
<Footer/>

</>
  )
}

export default Networking