import React from "react";
import "../Home/Home.css";
function Ads() {


  
  return (
    <>
      <div className="container-fluid adsection" >
        {/* <img src={require("../../Assets/rr (1).png")} style={{height:"35px",marginRight:"10px"}}/> */}
        <h6 className="fornumb" style={{ marginTop: "7px" }}>
          {" "}
          <a
            href="tel:048-3768405"
            style={{ textDecoration: "none", color: "white" }}
          >
            048-3768405{" "}
          </a>
          /{" "}
          <a
            href="tel:+923030918095"
            style={{ textDecoration: "none", color: "white" }}
          >
            +92-303-0918095
          </a>{" "}
          /{" "}
          <a
            href="tel:+923407460699"
            style={{ textDecoration: "none", color: "white" }}
          >
            +92-340-7460699
          </a>{" "}
        </h6>

        {/* <div className="h111">

<i class="fa-brands fa-facebook-f" style={{backgroundColor:"white",borderRadius:"50px",height:"25px",width:"25px",textAlign:"center",paddingTop:"4px",color:"blue"}}></i>
<i class="fa-brands fa-whatsapp mx-3" style={{backgroundColor:"white",borderRadius:"50px",height:"25px",width:"25px",textAlign:"center",paddingTop:"4px",color:"green"}}></i>
<i class="fa-brands fa-youtube " style={{backgroundColor:"white",borderRadius:"50px",height:"25px",width:"25px",textAlign:"center",paddingTop:"4px",color:"red"}}></i>
</div> */}
      </div>
    </>
  );
}

export default Ads;
