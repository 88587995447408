import React from 'react'
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'

function PropertyMangment() {
  return (
    <>
     <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Property Management Software</h3>

<p style={{textAlign:"justify"}}>Our Property Management Software is a comprehensive solution designed to streamline and enhance the management of real estate properties. Whether you're a property owner, manager, or tenant, our Property Management Software offers a user-friendly platform to simplify various aspects of property Management.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Property Listings
 </p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Tenant Management </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Rent and Financial Management</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Maintenance Tracking</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Document Management</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Data-driven Decision Making</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Secure and Scalable</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Enhanced Parent-Teacher Collaboration</strong></p>

</ul> */}
<p style={{textAlign:"justify"}}>Our Property Management Software streamlines tasks, saving time for property managers and fostering an efficient workflow. It ensures transparency for property owners and tenants, building trust through clear communication and provides precise financial tracking for accurate rent collection and expense management. The system's scalability easily adapts to a growing portfolio, making it a dynamic solution for evolving business needs.</p>
{/* <p><strong>Why Us?</strong></p> */}

{/* <ul>

    <p><strong>Easy to Use</strong><br/> No tech headaches here. Our interface is designed with you in mind, making it a breeze for your team to get the hang of things.</p>



    <p><strong>Support that Cares</strong><br/> Need help? Our support team is here for you, ready to assist with any questions or issues.</p>

</ul> */}

<p style={{textAlign:"justify"}}>Transform your property management experience with our comprehensive Property Management Software services. Get in touch for a personalized consultation.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default PropertyMangment