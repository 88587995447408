import React from 'react'
import Whatsapp from '../../Pages/Whatsapp/Whatsapp'

function Hotelmangment() {
  return (
   <>
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Hotel Managment Software</h3>

<p style={{textAlign:"justify"}}>Step into a new era of hospitality with our Hotel Management Software,  your all-in-one solution for seamless hotel operations and unforgettable guest experiences.</p>

<p><strong>Key Features</strong></p>

<ol>

  <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Smooth Reservations </p>


    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Automated Check-In/Check-Out </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Real-Time Room Updates</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>  Seamless Point of Sale</p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> Hassle-Free Billing</p>

</ol>

{/* <p><strong>Benefits That Matter</strong></p> */}

{/* <ul>

    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Unforgettable Guest Experiences</strong> </p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Operational Ease</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Boosted Revenue</strong></p>



    <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Rock-Solid Security</strong></p>

</ul> */}
<p style={{textAlign:"justify"}}>Primary advantages of our Hotel Management Software encompass error reduction, heightened guest satisfaction, and operational efficiency, resulting in significant time and resource savings.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Elevate your hotel's game with our Hotel Management Software. It's not just a Software; it's a partner in your success  intuitive, efficient, and always guest-centric.</p>



</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}} onClick={Whatsapp}>Whatsapp</button>
      </div>
    </div>
  </div>
   </>
  )
}

export default Hotelmangment