import React from "react";
import { Link } from "react-router-dom";
// import { AiFillInstagram, AiFillLinkedin, AiFillYoutube, AiOutlineTwitter } from "react-icons/ai";
// import { BiLogoFacebook } from "react-icons/bi";
// import { HiLocationMarker } from "react-icons/hi";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section
        className=""
        style={{
          backgroundColor: "#34383c",
          minHeight: "30vh",
          fontWeight: "5000",
          color: "white",
        }}
      >
        <div className="container-fluid px-5">
          <div className="row">
          <div className="col-md-3 mt-5">
              <h5>Company</h5>
              <p style={{ color: "#e0dede" }}>
              <Link to="/" class="nav-link" style={{ color: "#e0dede" ,fontSize:"15px",fontWeight:"100"}}>Home</Link>
              <Link to="/about" class="nav-link" style={{ color: "#e0dede" ,fontSize:"15px",fontWeight:"100"}}>About us</Link>
            
          <Link to="/contact" class="nav-link" style={{ color: "#e0dede" ,fontSize:"15px",fontWeight:"100"}}>Contact us</Link>
         </p>
            </div>
           
            <div className="col-md-3 mt-5">
              <h5>Services</h5>
              <p style={{ color: "#e0dede" }}>
              <Link to="/softwaredevelopment" class="nav-link" style={{ color: "#e0dede" ,fontSize:"15px",fontWeight:"100"}}>
                Software Development
              </Link>
              <Link to="/websitedevelopment" class="nav-link" style={{ color: "#e0dede" ,fontSize:"15px",fontWeight:"100"}}>
                Website Development
              </Link>
              <Link to="/applicationdevelopment" class="nav-link" style={{ color: "#e0dede",fontSize:"15px",fontWeight:"100" }}>
                Application Development
              </Link>
              <Link to="/accesscontrolsystems" class="nav-link" style={{ color: "#e0dede" ,fontSize:"15px",fontWeight:"100"}}>
                Access Control System
              </Link>
              <Link to="/cctv" class="nav-link" style={{ color: "#e0dede",fontSize:"15px",fontWeight:"100" }}>
                CCTV Serveillance
              </Link>
              <Link to="/Graphic" class="nav-link" style={{ color: "#e0dede",fontSize:"15px",fontWeight:"100" }}>
                Graphic Designing
              </Link>
              <Link to="/digitalmarketing" class="nav-link" style={{ color: "#e0dede",fontSize:"15px",fontWeight:"100" }}>
                Digital Marketing
              </Link>
              <Link to="/networking" class="nav-link" style={{ color: "#e0dede",fontSize:"15px",fontWeight:"100" }}>
                Networking
              </Link>
              </p>
            </div>
            
            <div className="col-md-3 mt-5">
              <h5>Conatct Us</h5>
          
              {/* <h6>Burlington, VT 05405 <HiLocationMarker color="yellow" size={20}/></h6> */}
              <p class="nav-link" style={{ color: "#e0dede" ,fontSize:"15px",fontWeight:"100"}}>
              <a
            href="tel:048-3768405"
            style={{ textDecoration: "none", color: "#e0dede" }}
          >
            048-3768405{" "}
          </a> <br />
          <a
            href="tel:+923030918095"
            style={{ textDecoration: "none", color: "#e0dede" }}
          >
            +92-303-0918095
          </a>
                <br />
                <a
            href="tel:+923407460699"
            style={{ textDecoration: "none", color: "#e0dede" }}
          >
            +92-340-7460699
          </a>
              

          
              </p>
            
             
     
           
            </div>




            <div className="col-md-3 mt-5">
              <h5>Address</h5>
              <p class="nav-link" style={{ color: "#e0dede" ,fontSize:"15px",fontWeight:"100"}}>Office No. 121,<br/> First Floor, Al-Rehman <br/> Trade  Center, University <br/> Road, Sargodha, Punjab,<br/> Pakistan</p>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          backgroundColor: "#34383c",
          minHeight: "10vh",
          fontWeight: "5000",
          color: "white",
        }}
      >
        <div className="row">
          <div
            style={{
              display: "flex",
              gap: "30px",
              color: "#e0dede",
              paddingTop: "20px",
              justifyContent: "center",
            }}
          >
            {/* <h6>Accessibility </h6> */}
            <h6> All rights reserved 
            © {currentYear} C WORLD</h6>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
