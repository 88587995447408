import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function SoialMediaMarketing() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Social Media Marketing </h3>

<p style={{textAlign:"justify"}}>Welcome to our Social Media Marketing service, where we craft dynamic strategies to elevate your brand in the digital landscape. Through engaging content, targeted advertising, and community building, we help businesses establish a robust online presence, fostering meaningful connections with their audience.</p>

<p><strong>Top Platforms</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Facebook
</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Instagram </p>



   {/* <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>LinkedIn</p> */}
   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>TikTok</p>
   {/* <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Twitter</p> */}




</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Boost your brand with our Social Media Marketing service. We strategically use platforms to amplify your brand, build a loyal community through direct communication, and utilize targeted advertising for precision. Share valuable content effortlessly, educating and entertaining your audience, and drive leads and conversions with our expertly crafted social media campaigns.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Maximize your brand's potential with our Social Media Marketing. Engage, inspire, and elevate your online presence across platforms.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default SoialMediaMarketing