import React from 'react'
import "../Home/OurProduct.css";
import AnalogCamera from './AnalogCamera/AnalogCamera';
import IpCamera from './IpCamera/IpCamera';
import PtzCamera from './PtzCamera/PtzCamera';
import WirelessCamera from './WirelessCamera/WirelessCamera';

function CctvComp() {
  return (
    <>
    <div className="container-fluid mt-3 flex-wrap mb-5" >
      <h1
        style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}
      >
CCTV Surveillance
      </h1>
      <p style={{ textAlign: "center" }}>
      Redefining security standards with trusted CCTV surveillance for your safety.
       </p>

<div className="row mt-3" style={{display:"flex",justifyContent:"center"}} >
<div className="fortab col-md-3 mt-3">
<div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>

<div class="card-body">
  <h5 class="card-title" >Analog Camera</h5>
  <p class="card-text" style={{alignItems:"center"}}>Step into reliable surveillance with our Analog Cameras, delivering fundamental security.</p>
  <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#analogcamera" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
</div>
</div>
</div>
<div className="col-md-3 mt-3">
<div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>

<div class="card-body">
  <h5 class="card-title" >IP Cameras</h5>
  <p class="card-text" style={{alignItems:"center"}}>Enhance your security infrastructure with cutting-edge IP cameras, the cornerstone of modern CCTV.</p>
  <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#ipcamera" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
</div>
</div>
</div>
<div className="col-md-3 mt-3">
<div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>

<div class="card-body">
  <h5 class="card-title" >PTZ Camera</h5>
  <p class="card-text" style={{alignItems:"center"}}>Experience unparalleled control and surveillance precision with our PTZ (Pan-Tilt-Zoom) cameras. </p>
  <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#ptzcamera" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
</div>
</div>
</div>
<div className="col-md-3 mt-3">
<div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
<div class="card-body">
  <h5 class="card-title" >Wireless Cameras</h5>
  <p class="card-text" style={{alignItems:"center"}}>Welcome to the future of surveillance with our Wireless Cameras. providing flexibility through wireless connectivity. </p>
  <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#wirelesscamera" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
</div>
</div>
</div>
</div>


    </div>





{/* modal */}



{/* <!-- Modal --> */}
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="analogcamera" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<AnalogCamera/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="ipcamera" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<IpCamera/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="ptzcamera" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<PtzCamera/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="wirelesscamera" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
<WirelessCamera/>
</div>


  </>
  )
}

export default CctvComp