import React from "react";
// import "./Home.css";
import "../Home/OurProduct.css";
import Pointofsale from "../Pointofsale/Pointofsale";
import Hotelmangment from "../HotelManagment/Hotelmangment";
import Schoolmangement from "../Schoolmangment/Schoolmangement";
import Hospitalmangment from "../HospitalMangment/Hospitalmangment";
import BusinessWeb from "./Businessweb/Businessweb";
import Ecommerceweb from "./Ecomerceweb/Ecommercewweb";
import Educatinalweb from "./EducationalWeb/Educatinalweb";
import Realestate from "./Realestate/Realestate";
import CostomizeSoftware from "../CostomizeSoftware/Costomizesoftware";
import Costmizedweb from "./Costmizeweb/Costmizedwe";

function Websitecomp() {
  return (
    <>
      <div className="container-fluid mt-3 mb-5 flex-wrap" >
        <h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}
        >
        Websites Development
        </h1>
        <p style={{ textAlign: "center" }}>
        Elevate your online presence with our professional website development services.
         </p>

<div className="row mt-3" >
  <div className="fortab col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",minHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Bussiness Website</h5>
    <p class="card-text" style={{alignItems:"center"}}>Welcome to our Business Website development service.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#businessWeb" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",minHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >E-commerce Website </h5>
    <p class="card-text" style={{alignItems:"center"}}>Empower your online business with our E-commerce solutions.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#ecommerce" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",minHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Educational Website </h5>
    <p class="card-text" style={{alignItems:"center"}}>Unlocking Educational Excellence in the Digital Era.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#educational" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",minHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Real Estate Website</h5>
    <p class="card-text" style={{alignItems:"center"}}> We create tailored online platforms with seamless design.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#realestate" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-4">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",minHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Agile/Customized Website </h5>
    <p class="card-text" style={{alignItems:"center"}}>Connect with us for a transformative online presence your business.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#customizedwebsite" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  
  </div>


      </div>





{/* modal */}



{/* <!-- Modal --> */}
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="businessWeb" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <BusinessWeb/>
</div>


<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="ecommerce" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Ecommerceweb/>
</div>

<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="educational" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Educatinalweb/>
</div>
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="realestate" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Realestate/>
</div>
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="customizedwebsite" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Costmizedweb/>
</div>
    </>
  );
}

export default Websitecomp;
