import React from "react";
// import "./Home.css";
import "../Home/OurProduct.css";
// import Pointofsale from "../Pointofsale/Pointofsale";
// import Hotelmangment from "../HotelManagment/Hotelmangment";
// import Schoolmangement from "../Schoolmangment/Schoolmangement";
// import Hospitalmangment from "../HospitalMangment/Hospitalmangment";
// import PropertyMangment from "../PropertyManagment/PropertyMangment";
// import Pharmacy from "../PharmacyMangment/Pharmacy";
// import CostomizeSoftware from "../CostomizeSoftware/Costomizesoftware";
// import Hardwareintegration from "../Hardwareintegration/Hardwareintegration";
import Android from "./Android/Android";
import Ios from "./Ios/Ios";

function ApplicationComp() {
  return (
    <>
      <div className="container-fluid mt-3 mb-5 flex-wrap" >
        <h1
          style={{ textAlign: "center", fontWeight: "1000", color: "#D66E1E" }}
        >
       Application Development
        </h1>
        <p style={{ textAlign: "center" }}>
    
        Boost online presence with our extensive App development services.
         </p>

<div className="row mt-3" style={{display:"flex",justifyContent:"center"}} >
  <div className="fortab col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >Android</h5>
    <p class="card-text" style={{alignItems:"center"}}>Our Android App development service harnesses the versatility of the platform</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#android" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>
  <div className="col-md-3 mt-3">
  <div class="cardbody11 card text-center " style={{minWidth: "18rem",position:"relative",border:"none",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
    {/* <img class="cardbody15" src={require("../../Assets/4.png")} style={{position:"absolute",maxHeight:"150px",marginLeft:"70px"}}/> */}
  <div class="card-body">
    <h5 class="card-title" >iOS</h5>
    <p class="card-text" style={{alignItems:"center"}}>Embark on a transformative journey with our iOS App services.</p>
    <a href="#" class="costom-new btn btn-primary" data-bs-toggle="modal" data-bs-target="#ios" style={{backgroundColor:"#DC761E",border:"none"}}>Read More</a>
  </div>
</div>
  </div>

  
  </div>


      </div>





{/* modal */}



{/* <!-- Modal --> */}
<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="android" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Android/>
</div>


<div style={{zIndex:"99999999999999999999"}} class="modal fade" id="ios" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
 <Ios/>
</div>


    </>
  );
}

export default ApplicationComp;
