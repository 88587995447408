import React from 'react'
import Whatsapp from '../../../Pages/Whatsapp/Whatsapp'

function BiomatricDevices() {
  return (
    <>
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
   <div class="modal-content">
     <div class="modal-header">
       <h1 class="modal-title fs-5" id="staticBackdropLabel"></h1>
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div class="modal-body">
     <div className='row p-4'>

<h3 style={{fontWeight:"bold"}}>Biometric Devices</h3>

<p style={{textAlign:"justify"}}>Step into the future of secure access and identity verification with our Biometric Devices. These cutting-edge solutions leverage unique biological traits for seamless and reliable authentication, ensuring a heightened level of security for your business.</p>

<p><strong>Key Features</strong></p>

<ol>

 <p>  <i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Biometric Authentication</p>


   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Integration with RFID cards </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Shift Scheduling</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Overtime tracking</p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i>Integration with payroll system</p>

</ol>

{/* <p><strong>Benefits You Can't Ignore:</strong></p> */}

{/* <ul>

   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Faster Operations</strong> </p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Accurate Record-Keeping</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Happy Customers</strong></p>



   <p><i class="fa-solid fa-check px-2" style={{color:"#D66E1E"}}></i> <strong>Security First</strong></p>

</ul> */}

<p style={{textAlign:"justify"}}>Experience heightened security with our Biometric Devices, providing robust access control for authorized individuals. Enjoy user-friendly authentication, eliminating the need for traditional keys or cards. Achieve unparalleled accuracy in identity verification, with built-in audit trail features ensuring meticulous access monitoring. Streamline attendance management by integrating Biometric Devices, automating record-keeping and reducing administrative overhead.</p>
{/* <p><strong>Why Us?</strong></p> */}



<p style={{textAlign:"justify"}}>Secure with confidence, where innovation meets simplicity in our Biometric Devices, redefining the future of access control.</p>



</div>
     </div>
     <div class="modal-footer">
       <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button onClick={Whatsapp} type="button" class="btn btn-primary" style={{backgroundColor:"#43C354",border:"none"}}>Whatsapp</button>
     </div>
   </div>
 </div>
   
   </>
  )
}

export default BiomatricDevices